import { Link } from "react-router-dom";
import styles from "./Desktop.module.scss";
import MenuLink from "../MenuLink";
import { useSendToSection } from "../../../../../hooks";

const Desktop = ({ logo, links, isTop }) => {
    const sendToSection = useSendToSection();
    
    return (
        <nav className={`${styles.navbar} ${isTop ? styles.isTop : ""}`}>
            <div className={styles.container}>
                <Link to={"/"} className={styles.logo}>
                    <img src={logo.src} alt={logo.alt}
                        onClick={(e) => {
                            const isHomepage = location.pathname === "/";
                            if (isHomepage) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            
                            console.log("HOME LINK CLICKED", {
                                isHomepage, currentPath: location.pathname
                            });
                            
                            sendToSection("page-content");
                        }}
                    />
                </Link>
                <ul className={styles.navMenu}>
                    {links.map(({ text, target, dropdown }, id) => (
                        <MenuLink
                            key={id}
                            text={text}
                            target={target}
                            dropdown={dropdown}
                        />
                    ))}
                </ul>
            </div>            
        </nav>
    )
}

export default Desktop;
import styles from "./HomePage.module.scss";
import services from "./services";
import SEOHelmet from "../SEOHelmet";
import { useLocation } from "react-router-dom";
import Hero from "./components/Hero";
import Banner from "./components/Banner";
import ServicePreview from "./components/ServicePreview";
import Cards from "./components/Cards";
import Reviews from "./components/Reviews";
import FAQs from "./components/FAQs/FAQs";


const HomePage = ({ scrollRef }) => {
    const location = useLocation();
    
    return (
        <main
            ref={scrollRef}
            className={styles.homepage} key={location.pathname} id="page-content">
            <SEOHelmet />
            <Hero />
            <Banner />
            <div key={location.pathname} style={{ padding: "4rem 0", backgroundColor: "#2B2A2A" }} >
                {services.map((service, id) => (
                    <ServicePreview content={service} key={id} />
                ))}
            </div>
            <Cards />
            <Reviews />
            <FAQs />
        </main>
    )
}

export default HomePage;
import { useLocation, useNavigate } from "react-router-dom";

const useSendToSection = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const sendToSection = (sectionId, destination = "/") => {
        const isSamePage = location.pathname === destination;
        
        const scrollToSection = () => {
            const container = document.getElementById(sectionId);
            if (container) {
                container.scrollTo({ top: 0, behavior: "auto" });
            }
        }
        
        if (isSamePage) {
            setTimeout(scrollToSection, 100);
            
        } else {
            navigate(destination);
            setTimeout(scrollToSection, 100);
        }
    }
    return sendToSection;
}

export default useSendToSection;
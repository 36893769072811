import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollStateRefresh = () => {
    const location = useLocation();
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.dispatchEvent(new Event("scroll"));
        }, 100);
        
        return clearTimeout(timeout);
        
    }, [location.pathname]);
    
    return null;
}

export default ScrollStateRefresh;
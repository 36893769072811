// import { useRef, useEffect } from "react";

// const useTeaserScroll = ({ teaserHeight = 30, scrollDuration = 350, hoverDelay = 200 } = {}) => {
//     const teaserRef = useRef(null);
//     const scrollLockRef = useRef(false);
//     const collapeCooldownRef = useRef(false);
//     const onlyShowTeaser = useRef(true);
//     const mouseOverRef = useRef(false);
//     const wheelCooldownRef = useRef(false);
//     const wheelReleaseTimeoutRef = useRef(null);
//     const hoverExpandTimeoutRef = useRef(null);
//     const hoverCollapseTimeoutRef = useRef(null);
    
//     const disableSnap = () => {
//         document.documentElement.style.scrollSnapType = "none";
//     }
    
//     const enableSnap = () => {
//         document.documentElement.style.scrollSnapType = "";
//     }
    
//     const expandElement = () => {
//         const element = teaserRef.current;
//         if (!element || scrollLockRef.current) return;
        
//         scrollLockRef.current = true;
//         onlyShowTeaser.current = false;
        
//         const bounds = element.getBoundingClientRect();
//         const scrollY = window.scrollY;
//         const elementBottom = bounds.bottom;
//         const viewportHeight = window.innerHeight;
        
//         disableSnap();
//         window.scrollTo({
//             top: scrollY + elementBottom - viewportHeight,
//             behavior: "smooth"
//         });
        
//         setTimeout(() => {
//             scrollLockRef.current = false;
//             enableSnap();
//         }, scrollDuration + 20);
//     }
    
//     const markAsCollapsed = () => {
//         scrollLockRef.current = false;
//         collapeCooldownRef.current = true;
//         onlyShowTeaser.current = true;
        
//         setTimeout(() => {
//             collapeCooldownRef.current = false;
//         }, scrollDuration + 200)
//     }
    
//     const collapseElement = () => {
//         const element = teaserRef.current;
//         if (!element || scrollLockRef.current) return;
        
//         scrollLockRef.current = true;
//         collapeCooldownRef.current = true;
//         onlyShowTeaser.current = true;
        
//         const bounds = element.getBoundingClientRect();
//         const scrollY = window.scrollY;
//         const teaserOffset = bounds.top + teaserHeight;
        
//         disableSnap();
//         window.scrollTo({
//             top: scrollY + teaserOffset - window.innerHeight,
//             behavior: "smooth"
//         });
        
//         setTimeout(() => {
//             scrollLockRef.current = false;
//             enableSnap();
//         }, scrollDuration + 20);
        
//         setTimeout(() => {
//             collapeCooldownRef.current = false;
//         }, scrollDuration + 200);
//     }
    
//     const forceNoScrollCollapse = () => {
//         markAsCollapsed();
//     }
    
//     useEffect(() => {
//         const syncCollapse = () => {
//             const element = teaserRef.current;
//             if (!element) return;
            
//             const bounds = element.getBoundingClientRect();
//             const scrollY = window.scrollY;
//             const teaserOffset = bounds.top + teaserHeight;
            
//             if (onlyShowTeaser.current) {
//                 disableSnap();
//                 window.scrollTo({
//                     top: scrollY + teaserOffset - window.innerHeight,
//                     behavior: "auto"
//                 });
//                 enableSnap();
//             }
//         }
//         syncCollapse();
        
//         const handlePostClick = () => {
//             requestAnimationFrame(syncCollapse);
//         }
        
//         const onScroll = () => {
//             if (scrollLockRef.current || collapeCooldownRef.current) return;
            
//             const element = teaserRef.current;
//             if (!element) return;
            
//             const bounds = element.getBoundingClientRect();
//             const teaserBottom = bounds.top + teaserHeight;
//             const viewportHeight = window.innerHeight;
            
//             const isOnlyTeaserShowingNow =
//                 Math.abs(teaserBottom - viewportHeight) < 5;
            
//             if (onlyShowTeaser.current && !isOnlyTeaserShowingNow) {
//                 expandElement();
//             }
//         }
        
//         const onWheel = (e) => {
//             if (wheelCooldownRef.current) {
//                 e.preventDefault();
//                 return;
//             }
            
//             const shouldCollapse =
//                 e.deltaY < 0 && !onlyShowTeaser.current;
            
//             if (shouldCollapse) {
//                 if (!mouseOverRef.current) {
//                     e.preventDefault();
//                 }
//                 collapseElement();
                
//                 wheelCooldownRef.current = true;
//                 clearTimeout(wheelReleaseTimeoutRef.current);
//                 wheelReleaseTimeoutRef.current = setTimeout(() => {
//                     wheelCooldownRef.current = false;
//                 }, 300);
//             }
//         }
        
//         const onMouseEnter = () => {
//             mouseOverRef.current = true;
            
//             clearTimeout(hoverCollapseTimeoutRef.current);
//             hoverExpandTimeoutRef.current = setTimeout(() => {
//                 if (onlyShowTeaser.current && !scrollLockRef.current) {
//                     expandElement();
//                 }
//             }, hoverDelay);
//         }
        
//         const onMouseLeave = () => {
//             mouseOverRef.current = false;
            
//             clearTimeout(hoverExpandTimeoutRef.current);
//             hoverCollapseTimeoutRef.current = setTimeout(() => {
//                 if (!onlyShowTeaser.current && !scrollLockRef.current) {
//                     collapseElement();
//                 }
//             }, hoverDelay);
//         }
        
//         const handleFooterLinkClick = (e) => {
//             const target = e.target.closest(".footer-link");
//             const element = teaserRef.current;
            
//             if (target && element?.contains(target)) {
//                 requestAnimationFrame(() => forceNoScrollCollapse());
//             }
//         }
        
//         const element = teaserRef.current;
        
//         if (element) {
//             element.addEventListener("mouseenter", onMouseEnter);
//             element.addEventListener("mouseleave", onMouseLeave);
//         }
        
//         window.addEventListener("scroll", onScroll);
//         window.addEventListener("wheel", onWheel, { passive: false });
//         document.addEventListener("mousedown", handleFooterLinkClick);
        
//         return () => {
//             window.removeEventListener("scroll", onScroll);
//             window.removeEventListener("wheel", onWheel);
//             document.removeEventListener("mousedown", handleFooterLinkClick);
            
//             if (element) {
//                 element.removeEventListener("mouseenter", onMouseEnter);
//                 element.removeEventListener("mouseleave", onMouseLeave);
//             }
//             clearTimeout(wheelReleaseTimeoutRef.current);
//             clearTimeout(hoverExpandTimeoutRef.current);
//             clearTimeout(hoverCollapseTimeoutRef.current);
//         }
//     }, [teaserHeight, scrollDuration, hoverDelay]);
    
//     return { teaserRef }
// }

// export default useTeaserScroll;

import { useRef, useEffect } from "react";

const useTeaserScroll = ({ teaserHeight = 30, scrollDuration = 350, hoverDelay = 200 } = {}) => {
    const teaserRef = useRef(null);
    const scrollLockRef = useRef(false);
    const collapeCooldownRef = useRef(false);
    const onlyShowTeaser = useRef(true);
    const mouseOverRef = useRef(false);
    const wheelCooldownRef = useRef(false);
    const wheelReleaseTimeoutRef = useRef(null);
    const hoverExpandTimeoutRef = useRef(null);
    const hoverCollapseTimeoutRef = useRef(null);

    const disableSnap = () => {
        document.documentElement.style.scrollSnapType = "none";
    };

    const enableSnap = () => {
        document.documentElement.style.scrollSnapType = "";
    };

    const expandElement = () => {
        const element = teaserRef.current;
        if (!element || scrollLockRef.current) return;

        scrollLockRef.current = true;
        onlyShowTeaser.current = false;

        const bounds = element.getBoundingClientRect();
        const scrollY = window.scrollY;
        const elementBottom = bounds.bottom;
        const viewportHeight = window.innerHeight;

        disableSnap();
        window.scrollTo({
            top: scrollY + elementBottom - viewportHeight,
            behavior: "smooth"
        });

        setTimeout(() => {
            scrollLockRef.current = false;
            enableSnap();
        }, scrollDuration + 20);
    };

    const markAsCollapsed = () => {
        scrollLockRef.current = false;
        collapeCooldownRef.current = true;
        onlyShowTeaser.current = true;

        setTimeout(() => {
            collapeCooldownRef.current = false;
        }, scrollDuration + 200);
    };

    const collapseElement = () => {
        const element = teaserRef.current;
        if (!element || scrollLockRef.current) return;

        scrollLockRef.current = true;
        markAsCollapsed();

        const bounds = element.getBoundingClientRect();
        const scrollY = window.scrollY;
        const teaserOffset = bounds.top + teaserHeight;

        disableSnap();
        window.scrollTo({
            top: scrollY + teaserOffset - window.innerHeight,
            behavior: "smooth"
        });

        setTimeout(() => {
            scrollLockRef.current = false;
            enableSnap();
        }, scrollDuration + 20);
    };

    const forceNoScrollCollapse = () => {
        markAsCollapsed();
    };

    useEffect(() => {
        const onScroll = () => {
            if (scrollLockRef.current || collapeCooldownRef.current) return;

            const element = teaserRef.current;
            if (!element) return;

            const bounds = element.getBoundingClientRect();
            const teaserBottom = bounds.top + teaserHeight;
            const viewportHeight = window.innerHeight;

            const isOnlyTeaserShowingNow = Math.abs(teaserBottom - viewportHeight) < 5;

            if (onlyShowTeaser.current && !isOnlyTeaserShowingNow) {
                expandElement();
            }
        };

        const onWheel = (e) => {
            if (wheelCooldownRef.current) {
                e.preventDefault();
                return;
            }

            const shouldCollapse = e.deltaY < 0 && !onlyShowTeaser.current;

            if (shouldCollapse) {
                if (!mouseOverRef.current) {
                    e.preventDefault();
                }
                collapseElement();

                wheelCooldownRef.current = true;
                clearTimeout(wheelReleaseTimeoutRef.current);
                wheelReleaseTimeoutRef.current = setTimeout(() => {
                    wheelCooldownRef.current = false;
                }, 300);
            }
        };

        const onMouseEnter = () => {
            mouseOverRef.current = true;

            clearTimeout(hoverCollapseTimeoutRef.current);
            hoverExpandTimeoutRef.current = setTimeout(() => {
                if (onlyShowTeaser.current && !scrollLockRef.current) {
                    expandElement();
                }
            }, hoverDelay);
        };

        const onMouseLeave = () => {
            mouseOverRef.current = false;

            clearTimeout(hoverExpandTimeoutRef.current);
            hoverCollapseTimeoutRef.current = setTimeout(() => {
                if (!onlyShowTeaser.current && !scrollLockRef.current) {
                    collapseElement();
                }
            }, hoverDelay);
        };

        const handleFooterLinkClick = (e) => {
            const target = e.target.closest(".footer-link");
            const element = teaserRef.current;

            if (target && element?.contains(target)) {
                requestAnimationFrame(() => forceNoScrollCollapse());
            }
        };

        const element = teaserRef.current;

        if (element) {
            element.addEventListener("mouseenter", onMouseEnter);
            element.addEventListener("mouseleave", onMouseLeave);
        }

        window.addEventListener("scroll", onScroll);
        window.addEventListener("wheel", onWheel, { passive: false });
        document.addEventListener("mousedown", handleFooterLinkClick);

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("wheel", onWheel);
            document.removeEventListener("mousedown", handleFooterLinkClick);

            if (element) {
                element.removeEventListener("mouseenter", onMouseEnter);
                element.removeEventListener("mouseleave", onMouseLeave);
            }

            clearTimeout(wheelReleaseTimeoutRef.current);
            clearTimeout(hoverExpandTimeoutRef.current);
            clearTimeout(hoverCollapseTimeoutRef.current);
        };
    }, [teaserHeight, scrollDuration, hoverDelay]);

    // ✅ Auto-sync scroll to teaser after link click or route change
    useEffect(() => {
        const syncCollapse = () => {
            const element = teaserRef.current;
            if (!element) return;

            const bounds = element.getBoundingClientRect();
            const scrollY = window.scrollY;
            const teaserOffset = bounds.top + teaserHeight;

            if (onlyShowTeaser.current) {
                disableSnap();
                window.scrollTo({
                    top: scrollY + teaserOffset - window.innerHeight,
                    behavior: "auto"
                });
                enableSnap();
            }
        };

        syncCollapse();

        const handlePostClick = () => {
            requestAnimationFrame(syncCollapse);
        };

        window.addEventListener("load", handlePostClick);
        document.addEventListener("click", handlePostClick);

        return () => {
            window.removeEventListener("load", handlePostClick);
            document.removeEventListener("click", handlePostClick);
        };
    }, [teaserHeight]);

    return { teaserRef };
};

export default useTeaserScroll;

import { Link, useLocation } from "react-router-dom";
import styles from "./MenuLink.module.scss";
import { useSendToSection } from "../../../../../hooks/";

const MenuLink = ({ text, target, dropdown }) => {
    const sendToSection = useSendToSection();
    const location = useLocation();
    
    return (
        <li className={styles.menuLink}>
            <Link
                to={target} className={styles.mainLink}
                onClick={(e) => {
                    const isSamePage = location.pathname === target;
                    if (isSamePage) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    
                    console.log("NAVBAR LINK CLICKED", {
                        target, isSamePage, currentPath: location.pathname
                    });
                    
                    sendToSection("page-content", target);
                }}
            >{text}</Link>
            <div className={styles.dropdown}>
                {dropdown.map(({ target, text }, id) => (
                    <Link
                        key={id}
                        to={target}
                        className={styles.subLink}
                        onClick={(e) => {
                            const isSamePage = location.pathname === target;
                            if (isSamePage) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            
                            console.log("NAVBAR DROPDOWN LINK CLICKED", {
                                target, isSamePage, currentPath: location.pathname
                            });
                            
                            sendToSection("page-content", target);
                        }}
                    >
                        {text}
                    </Link>
                ))}
            </div>
        </li>
    )
}

export default MenuLink;
import { useEffect } from "react";

const useSectionLayout = (sectionRef, imgRef, innerRef) => {
    useEffect(() => {
        if (!sectionRef.current || !imgRef.current || !innerRef.current) return;
        
        const img = imgRef.current;
        
        const updateLayout = () => {
            const imgHeight = img.offsetHeight;
            const innerHeight = innerRef.current.offsetHeight;
            
            if (imgHeight === 0) {
                requestAnimationFrame(updateLayout);
                return;
            }
            
            sectionRef.current.style.height = `${imgHeight}px`;
            
            const vertOffset = (imgHeight - innerHeight) / 2;
            innerRef.current.style.position = "absolute";
            innerRef.current.style.top = `${vertOffset}px`;
        }
        
        if (!img.complete || img.offsetHeight === 0) {
            img.onload = () => requestAnimationFrame(updateLayout);
            requestAnimationFrame(updateLayout);
            
        } else {
            requestAnimationFrame(updateLayout);
        }
        
        window.addEventListener("resize", updateLayout);
        
        return () => {
            window.removeEventListener("resize", updateLayout);
            if (img) img.onload = null;
        }
    }, [sectionRef, imgRef, innerRef]);
}

export default useSectionLayout;
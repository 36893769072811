import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useIsPageTop(containerRef) {
  const location = useLocation();
  const [isPageTop, setIsPageTop] = useState(true); // assume at top initially

  useEffect(() => {
    const container = containerRef?.current || window;  // use provided container or window

    const handleScroll = () => {
      if (container === window) {
        // Use window.scrollY if the scroll container is the window
        setIsPageTop(window.scrollY === 0);
        
      } else {
        // Use scrollTop for custom scrollable element
        setIsPageTop(container.scrollTop === 0);
      }
    };

    // Attach scroll listener
    container.addEventListener('scroll', handleScroll);
    handleScroll();  // initial check on mount

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, containerRef?.current, location.pathname]);

  return isPageTop;
}

export default useIsPageTop;